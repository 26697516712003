import { LocalStorageService } from 'ngx-webstorage';
import { isSharedWorkerEnabled } from '@webclient/myphone/shared-worker-state';
import { SharedWorkerRequest } from '@webclient/worker/protocol/shared-worker-request';

export function sharedWorkerFactory(storage: LocalStorageService) {
    if (isSharedWorkerEnabled(storage)) {
        const worker = new SharedWorker(new URL('../worker/myphone.worker', import.meta.url), { type: 'module', name: '3cx.myphone.worker' });

        window.addEventListener('beforeunload', () => {
            worker.port.postMessage(new SharedWorkerRequest({
                type: 'disconnect'
            }));
        });

        return worker;
    }
    return undefined;
}
