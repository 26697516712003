import { Component, inject } from '@angular/core';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { combineLatest, Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { protoBufToJsDateTime } from '@webclient/shared/utils.service';
import { TokenInfoService } from '@webclient/auth/token-info.service';
import { CanDoAnything, CanManageGroups } from '@office/users/user-general/roles';
import { LicenseStatusApiService } from '@xapi';
import { publishRef } from '@webclient/rx-share-utils';
import { ExclamationTriangleRegularIconComponent } from '../shared/components/icons/exclamation-triangle-regular-icon.component';
import { TranslatePipe } from '@ngx-translate/core';
import { AsyncPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-license-banner',
    animations: [
        trigger('fadeInOutAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('2s 0.5s', style({ opacity: 0.75 }))
            ]),
            transition(':leave', [
                style({ opacity: 0.75 }),
                animate('2s 0.5s', style({ opacity: 0 }))
            ])
        ])
    ],
    template: `
        <div class='d-flex flex-column align-items-end justify-content-center gap-2 me-1'>
            @if (licenseExpirationLink$ | async) {
            <div [@fadeInOutAnimation]='true' class='banner mb-0 p-2 d-flex gap-2'>
                <span class='banner-icon svg-sm' app-exclamation-triangle-regular-icon></span>
                <span
                    [innerHTML]="'_i18n.LicenseExpiresWarning' | translate: {date: licenseExpirationDate | date, link: licenseExpirationLink$ | async }"></span>
            </div>
            }
        </div>
    `,
    styleUrls: ['./license-banner.component.scss'],
    standalone: true,
    imports: [ExclamationTriangleRegularIconComponent, TranslatePipe, AsyncPipe, DatePipe]
})
export class LicenseBannerComponent {
    private myPhoneService = inject(MyPhoneService);
    private tokenInfoService = inject(TokenInfoService);
    private licenseStatusService = inject(LicenseStatusApiService);

    readonly licenseExpirationLink$ :Observable<string>;
    readonly moreInfoLink = 'https://www.3cx.com/contact/';
    licenseExpirationDate: Date | undefined;

    constructor() {
        const systemParameters$ = this.myPhoneService.myPhoneSession.pipe(
            switchMap((session) => session.systemParameters$)
        );

        const myRole$ = this.tokenInfoService.myToken$.pipe(
            map((myToken) => myToken.myRole),
            publishRef()
        );

        this.licenseExpirationLink$ = combineLatest([
            myRole$,
            systemParameters$,
            timer(0, 1000 * 60 * 60 * 6) // fire every 6 hours
        ]).pipe(
            switchMap(([userRole, systemParameters]) => {
                this.licenseExpirationDate = protoBufToJsDateTime(systemParameters.LicenseExpiredAt);

                if (!this.licenseExpirationDate) {
                    return of('');
                }

                const daysLeft = this.daysUntilExpirationDate(this.licenseExpirationDate);

                // for system owner/admin we need to get the license key and include it in the link
                if (daysLeft <= 30 && CanDoAnything.includes(userRole)) {
                    return this.licenseStatusService.getLicenseStatus({
                        $select: ['LicenseKey']
                    }).pipe(
                        map((licenseStatus) => {
                            return licenseStatus.LicenseKey ? `${this.moreInfoLink}?category=renewal&key=${licenseStatus.LicenseKey}` : this.moreInfoLink;
                        }),
                        catchError((err: unknown) => {
                            console.error(err);
                            return of(this.moreInfoLink);
                        })
                    );
                }
                // if the user can manage groups we show the default link
                else if (!systemParameters.MultiCompanyMode && daysLeft <= 15 && CanManageGroups.includes(userRole)) {
                    return of(this.moreInfoLink);
                }
                // for everybody else we show nothing
                return of('');
            }),
            publishRef()
        );
    }

    private daysUntilExpirationDate(date: Date) {
        const now = new Date();
        const timeDifference = date.getTime() - now.getTime();
        return timeDifference / (1000 * 60 * 60 * 24);
    }
}
