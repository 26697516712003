import { Component, OnInit, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import {
    animate, style, transition, trigger
} from '@angular/animations';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-update-notification-bar',
    template: `
        @if ((updateNotification$ | async) && showUpdateNotification) {
        <div @updateNotificationTrigger>
            <div class="d-flex flex-row gap-2 py-1 justify-content-center align-items-center">
                <span [translate]="label"></span>
                    <button type="button" class="btn btn-primary px-1 py-0 h-auto" (click)="installApp()">
                        {{'_i18n.Reload' | translate}}
                    </button>
            </div>
        </div>
        }
    `,
    animations: [
        trigger('updateNotificationTrigger', [
            transition(':enter', [
                style({ height: 0 }),
                animate('100ms ease-in-out', style({ height: '*' })),
            ]),
            transition(':leave', [
                animate('100ms ease-in-out', style({ height: 0 }))
            ])
        ])
    ],
    standalone: true,
    imports: [
        TranslateDirective,
        TranslatePipe,
        AsyncPipe,
    ],
})
export class UpdateNotificationBarComponent implements OnInit {
    swUpdate = inject(SwUpdate);

    readonly label = '_i18n.UpdatePwaIsAvailable';

    public updateNotification$: Observable<boolean>;

    ngOnInit(): void {
        this.updateNotification$ = this.swUpdate.isEnabled
            ? this.swUpdate.versionUpdates.pipe(
                filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
                map(() => true),
                startWith(false)
            )
            : of(false);
    }

    get showUpdateNotification() {
        return this.swUpdate.isEnabled;
    }

    installApp() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.activateUpdate()
                .catch(() => true)
                .then((activated) => {
                    if (activated) {
                        window.location.reload();
                    }
                });
        }
    }
}
