import { Component, DestroyRef, OnDestroy, OnInit, ViewContainerRef, inject } from '@angular/core';
import { AppState } from '../app.service';
import { ModalService } from '../modal/app-modal.service';
import { LangService } from '@webclient/l10n/lang.service';
import { Title } from '@angular/platform-browser';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { defaultTeamLink, LocalStorageKeys } from '../settings/local-storage-keys';
import { RouterTitleService } from '@webclient/router-title.service';
import { ThemeListenerService } from '@webclient/services/theme-listener.service';
import { delay, map, take } from 'rxjs/operators';
import Silent from '../../notifications/Silent.mp3';
import { ExtendedSwPushService } from '@webclient/notifications/extended-sw-push.service';
import { SilentModeService } from '@webclient/layout/silent-mode.service';
import { NavigationHistoryService } from '@webclient/navigation-history.service';
import { DialerService } from '@webclient/call/dialer.service';
import { DialerVisibilityService } from '@webclient/call-adapter/dialer-visibility.service';
import { AppsUtilityService } from '@webclient/services/apps-utility.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UpdateNotificationBarComponent } from './update-notification-bar/update-notification-bar.component';
import { PushStatusDisclaimerComponent } from './push-status-disclaimer/push-status-disclaimer.component';
import { RouterOutlet } from '@angular/router';
import { isSelenium } from '@webclient/environment';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    imports: [
        UpdateNotificationBarComponent,
        PushStatusDisclaimerComponent,
        RouterOutlet,
    ],
})
export class AppComponent implements OnInit, OnDestroy {
    app = inject(AppState);
    private routerTitle = inject(RouterTitleService);
    private navigationService = inject(NavigationHistoryService);
    private lang = inject(LangService);
    private silentModeService = inject(SilentModeService);
    private title = inject(Title);
    private themeListenerService = inject(ThemeListenerService);
    private extendedSwPushService = inject(ExtendedSwPushService);
    private appsUtilityService = inject(AppsUtilityService);
    private destroyService = inject(DestroyRef);

    public name = '3CX';
    public url = 'https://3cx.com';
    private themeSubscription: Subscription;
    private registerShortcutsSubscription: Subscription;
    private userPageInteractionSubscription: Subscription;

    @LocalStorage(LocalStorageKeys.SavePeople, defaultTeamLink)
    peopleLink: any;

    constructor() {
        const vcRef = inject(ViewContainerRef);
        const modalService = inject(ModalService);
        const storage = inject(LocalStorageService);
        const dialerService = inject(DialerService);
        const dalerVisibilityService = inject(DialerVisibilityService);

        if (isSelenium) {
            console.log('Disabling CSS animations');
            document.body.classList.add('notransition');
        }

        // Patch for those unlucky with null
        if (!storage.retrieve(LocalStorageKeys.HiddenGroups)) {
            storage.store(LocalStorageKeys.HiddenGroups, []);
        }

        modalService.applicationViewContainerRef = vcRef;

        // Handling tel protocol for PWA
        if ('launchQueue' in window) {
            (window as any).launchQueue.setConsumer((launchParams: any) => {
                if (launchParams.targetURL) {
                    const params = new URL(launchParams.targetURL).hash;

                    if (params.startsWith('#/tel/')) {
                        const query = params.split('/')[2];
                        const s = query.split('=')[1];
                        const telUrl = new URL(decodeURIComponent(s));
                        const tel = decodeURIComponent(telUrl.pathname);

                        dialerService.setTypedPhone(tel);
                        dalerVisibilityService.toggleDialer.next(true);
                    }
                }
            });
        }
    }

    public ngOnInit() {
        this.appsUtilityService.listenPwaInstallationEvents();
        this.navigationService.startNavigationHistory();

        this.routerTitle.title$.pipe(
            map((title) => {
                return title ? `${title} - ${this.name}` : this.name;
            }),
            takeUntilDestroyed(this.destroyService)
        ).subscribe(title => {
            this.title.setTitle(title);
        });

        // Set current language only once on load
        this.lang.localStorageLanguage$.subscribe(
            language => this.lang.applyLanguage(language));

        // set silence mode for the 3cx worker
        this.silentModeService.silentMode$.subscribe(
            silentMode => this.extendedSwPushService.setSilentMode(silentMode)
        );

        this.themeSubscription = this.themeListenerService.applicationTheme$.subscribe((selectedTheme) => {
            this.themeListenerService.applyTheme(selectedTheme);
        });

        // Play a silent sound on the first user interaction to enable sounds
        {
            const clickEvent$: Observable<MouseEvent> = fromEvent<MouseEvent>(document, 'click');
            const keyDownEvent$: Observable<KeyboardEvent> = fromEvent<KeyboardEvent>(document, 'keydown');
            const userPageInteraction$ = merge(clickEvent$, keyDownEvent$).pipe(take(1), delay(50));
            this.userPageInteractionSubscription = userPageInteraction$.subscribe(() => {
                const silentSound = new Audio(Silent);
                silentSound.play().catch(() => { /* Suppress error */
                });
            });
        }
    }

    public ngOnDestroy() {
        this.themeSubscription.unsubscribe();
        this.registerShortcutsSubscription?.unsubscribe();
        this.userPageInteractionSubscription?.unsubscribe();
    }
}
