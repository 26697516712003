import { Component, DestroyRef, OnDestroy, inject } from '@angular/core';
import { MyPhoneService } from '../myphone/myphone.service';
import { SplashService } from '../splash.service';
import { filter, pairwise, take } from 'rxjs/operators';
import { observe } from '@webclient/rx-utils';
import { LocalStorageKeys } from '@webclient/settings/local-storage-keys';
import { LocalStorageService } from 'ngx-webstorage';
import { Router, RouterOutlet } from '@angular/router';
import { ApplyLangService } from '@webclient/l10n/apply-lang.service';
import { BrandingService } from '@webclient/branding.service';
import { LicenseBannerComponent } from './license-banner.component';
import { SnackbarComponent } from '@webclient/myphone-session/snackbar.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ImpersonationBannerComponent } from '@webclient/layout/impersonation-banner/impersonation-banner.component';

@Component({
    styles: [`
        :host {
            display: grid;
            height: 100%;
            grid-template-rows: auto 1fr;
        }
    `],
    template: `
        <app-impersonation-banner></app-impersonation-banner>
        <app-license-banner></app-license-banner>
        <div class="overflow-auto">
            <router-outlet></router-outlet>
        </div>
        <snackbar [reconnectTime]="myPhoneService.reconnectTime"
                  (retryNow)="myPhoneService.retryNow$.next ()"
                  [isVisible]="myPhoneService.isReconnecting" ></snackbar>
    `,
    imports: [LicenseBannerComponent, RouterOutlet, SnackbarComponent, ImpersonationBannerComponent]
})
export class MyphoneSessionComponent implements OnDestroy {
    protected myPhoneService = inject(MyPhoneService);

    constructor() {
        const myPhoneService = this.myPhoneService;
        const splashService = inject(SplashService);
        const localStorageService = inject(LocalStorageService);
        const router = inject(Router);
        const destroyRef = inject(DestroyRef);
        const lang = inject(ApplyLangService);
        const brandingService = inject(BrandingService);

        myPhoneService.connect();

        // Remove splash when connected
        myPhoneService.myPhoneSession.pipe(take(1), takeUntilDestroyed(destroyRef)).subscribe(() => {
            splashService.remove();
        });

        // Save user lang in local storage for reference
        lang.sessionLanguage$.pipe(
            takeUntilDestroyed(destroyRef)
        ).subscribe((sessionLanguage) => {
            localStorageService.store(LocalStorageKeys.Language, sessionLanguage);
        });

        // ???
        observe(localStorageService, LocalStorageKeys.LogoutTimestamp, -1).pipe(
            pairwise(),
            filter(([, newTimeStamp]) => newTimeStamp > 0),
            takeUntilDestroyed(destroyRef)
        ).subscribe(() => {
            router.navigate(['/login']);
        });

        // Apply avatar style
        brandingService.AvatarStyle$.pipe(
            takeUntilDestroyed(destroyRef)
        ).subscribe((avatarStyle) => {
            brandingService.attachAvatarStyleClassToBody(avatarStyle);
        });
    }

    ngOnDestroy() {
        this.myPhoneService.disconnect();
    }
}
