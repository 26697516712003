import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import ipAddr from 'ipaddr.js';
import { TranslateDirective } from '@ngx-translate/core';

const { isValid } = ipAddr;

@Component({
    selector: 'app-push-status-disclaimer',
    template: `
        @if (insecure) {
        <div class="d-flex flex-row gap-2 py-1 justify-content-center align-items-center">
            <span [translate]="'_i18n.3CXRequiresSecureConnection'"></span>
        </div>
        }
        @if (localhostInDev) {
        <div class="d-flex flex-row gap-2 py-1 justify-content-center align-items-center">
            <span>Please do not use localhost in dev to avoid DNS related delays. Use 127.0.0.1</span>
        </div>
        }
  `,
    styles: [`
        :host {
            background-color: red;
            color: white;
            font-weight: bold;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslateDirective]
})
export class PushStatusDisclaimerComponent {
    readonly insecure: boolean;
    readonly localhostInDev: boolean;
    constructor(
    ) {
        let hostname = window.location.hostname;
        if (hostname.startsWith('[') && hostname.endsWith(']')) {
            hostname = hostname.substring(1, hostname.length - 1);
        }
        this.insecure = environment.production && (
            window.location.protocol === 'http:' || isValid(hostname) || hostname.toLowerCase() === 'localhost'
        );
        this.localhostInDev = !environment.production && (
            hostname.toLowerCase() === 'localhost'
        );
    }
}
