/* tslint:disable */
/* eslint-disable */
/**
 * 3CX PhoneSystem Webclient API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    token_type: string;
    /**
     * 
     * @type {number}
     * @memberof TokenResponse
     */
    expires_in: number;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    access_token: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    refresh_token: string;
}

/**
 * Check if a given object implements the TokenResponse interface.
 */
export function instanceOfTokenResponse(value: object): value is TokenResponse {
    if (!('token_type' in value) || value['token_type'] === undefined) return false;
    if (!('expires_in' in value) || value['expires_in'] === undefined) return false;
    if (!('access_token' in value) || value['access_token'] === undefined) return false;
    if (!('refresh_token' in value) || value['refresh_token'] === undefined) return false;
    return true;
}

export function TokenResponseFromJSON(json: any): TokenResponse {
    return TokenResponseFromJSONTyped(json, false);
}

export function TokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'token_type': json['token_type'],
        'expires_in': json['expires_in'],
        'access_token': json['access_token'],
        'refresh_token': json['refresh_token'],
    };
}

export function TokenResponseToJSON(value?: TokenResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token_type': value['token_type'],
        'expires_in': value['expires_in'],
        'access_token': value['access_token'],
        'refresh_token': value['refresh_token'],
    };
}

