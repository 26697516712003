/* tslint:disable */
/* eslint-disable */
/**
 * 3CX PhoneSystem Webclient API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TokenResponse,
} from '../models/index';
import {
    TokenResponseFromJSON,
    TokenResponseToJSON,
} from '../models/index';

export interface TokenTenantTokenRequest {
    tenant: string;
    grantType: string;
    clientId?: string;
    clientSecret?: string;
    expiresInMinutes?: number;
}

export interface TokenTokenRequest {
    grantType: string;
    impersonate?: string;
    clientId?: string;
    clientSecret?: string;
    scope?: string;
    refreshToken?: string;
    expiresInMinutes?: number;
}

/**
 * 
 */
export class TokenApi extends runtime.BaseAPI {

    /**
     */
    async tokenTenantTokenRaw(requestParameters: TokenTenantTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenResponse>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling tokenTenantToken().'
            );
        }

        if (requestParameters['grantType'] == null) {
            throw new runtime.RequiredError(
                'grantType',
                'Required parameter "grantType" was null or undefined when calling tokenTenantToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['clientId'] != null) {
            formParams.append('client_id', requestParameters['clientId'] as any);
        }

        if (requestParameters['clientSecret'] != null) {
            formParams.append('client_secret', requestParameters['clientSecret'] as any);
        }

        if (requestParameters['grantType'] != null) {
            formParams.append('grant_type', requestParameters['grantType'] as any);
        }

        if (requestParameters['expiresInMinutes'] != null) {
            formParams.append('expires_in_minutes', requestParameters['expiresInMinutes'] as any);
        }

        const response = await this.request({
            path: `/connect/{tenant}/token`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async tokenTenantToken(requestParameters: TokenTenantTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenResponse> {
        const response = await this.tokenTenantTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tokenTokenRaw(requestParameters: TokenTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenResponse>> {
        if (requestParameters['grantType'] == null) {
            throw new runtime.RequiredError(
                'grantType',
                'Required parameter "grantType" was null or undefined when calling tokenToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['impersonate'] != null) {
            formParams.append('impersonate', requestParameters['impersonate'] as any);
        }

        if (requestParameters['clientId'] != null) {
            formParams.append('client_id', requestParameters['clientId'] as any);
        }

        if (requestParameters['clientSecret'] != null) {
            formParams.append('client_secret', requestParameters['clientSecret'] as any);
        }

        if (requestParameters['scope'] != null) {
            formParams.append('scope', requestParameters['scope'] as any);
        }

        if (requestParameters['grantType'] != null) {
            formParams.append('grant_type', requestParameters['grantType'] as any);
        }

        if (requestParameters['refreshToken'] != null) {
            formParams.append('refresh_token', requestParameters['refreshToken'] as any);
        }

        if (requestParameters['expiresInMinutes'] != null) {
            formParams.append('expires_in_minutes', requestParameters['expiresInMinutes'] as any);
        }

        const response = await this.request({
            path: `/connect/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async tokenToken(requestParameters: TokenTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenResponse> {
        const response = await this.tokenTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
