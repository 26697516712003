import {
    HttpErrorResponse,
    HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
    Observable, of, retry, switchMap, throwError
} from 'rxjs';
import { TokenService } from '@webclient/auth/token.service';
import { catchError, take } from 'rxjs/operators';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { Router } from '@angular/router';
import { defaultTeamLink } from '@webclient/settings/local-storage-keys';
import { reloadToTeams } from '@office/funcs';

const extraHeaders = {
    'Cache-Control': 'no-store',
    Pragma: 'no-cache',
    'ngsw-bypass': 'bypass'
};

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
    private tokenService = inject(TokenService);
    private myPhoneService = inject(MyPhoneService);
    private router = inject(Router);

    private navigateLogin() {
        // Something is wrong with our authorization
        // ask for a different auth here
        this.myPhoneService.gotoLogin();
        this.myPhoneService.disconnect();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (/^(\.?\/)?media\//.test(request.url)) {
            // Normally cache everyhting in the media
            return next.handle(request);
        }
        else if (!/^\/?webclient\/api|xapi/.test(request.url) || /^\/?webclient\/api\/Login/.test(request.url)) {
            // These are requests with no authentication or non-token based (MyPhone)
            return next.handle(request.clone({
                setHeaders: extraHeaders
            })).pipe(
                retry({
                    delay: (error) => {
                        if (error instanceof HttpErrorResponse && (error.status === 403) && this.tokenService.impersonateUsername) {
                            // We are trying to impersonate so drop it and retry
                            this.tokenService.dropImpersonation();
                            reloadToTeams();

                            return of(true);
                        }
                        throw error;
                    }
                }),
                catchError((error: unknown) => {
                    /**
                     * This request should always succeed. If it fails it means out refresh token is invalid
                     * or we're really blacklisted
                     */
                    if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
                        this.navigateLogin();
                    }
                    return throwError(() => error);
                })
            );
        }
        // Requests with JWT authentication
        return this.tokenService.accessToken$.pipe(
            take(1),
            switchMap(accessToken => next.handle(request.clone({
                setHeaders: {
                    ...extraHeaders,
                    Authorization: 'Bearer ' + accessToken
                }
            }))),
            retry({
                count: 1,
                delay: (error: unknown) => {
                    if (error instanceof HttpErrorResponse && error.status === 401) {
                        // Invalidate access token
                        this.tokenService.invalidate();
                        // Retry the request immediately
                        return of(true);
                    }
                    throw error;
                }
            }),
            catchError((error: unknown) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        // We retried access token and still got 401
                        // should not be like this but go to login in this case
                        this.navigateLogin();
                    }
                    else if (error.status === 403) {
                        // Access token is valid but we access forbidden resource
                        // If we're really blacklisted we still can go to teams
                        this.router.navigate(defaultTeamLink);
                    }
                }
                return throwError(() => error);
            }),
        );
    }
}
