import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { LocalStorageKeys } from '@webclient/settings/local-storage-keys';
import { AsyncPipe } from '@angular/common';
import { TranslatePipe } from '@ngx-translate/core';
import { TokenInfoService } from '@webclient/auth/token-info.service';
import { LocalStorageService } from 'ngx-webstorage';
import { reloadToTeams } from '@office/funcs';
import { Observable } from 'rxjs';
import { AppContact, defaultContact } from '@webclient/myphone/contact';
import { startWith } from 'rxjs/operators';
import { myContact } from '@webclient/standalones/my-name';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { NameOrderPipe } from '@webclient/shared/pipes/name-order.pipe';

@Component({
    selector: 'app-impersonation-banner',
    imports: [AsyncPipe, TranslatePipe, NameOrderPipe],
    template: `
        @if ({
            token: tokenInfoService.myToken$|async,
            impersonatedContact: impersonatedContact$ | async
        }; as context) {
            @if (context.token && context.token.originalName && context.impersonatedContact){
                <div class="d-flex flex-row gap-2 py-1 justify-content-center align-items-center">
                    <span>{{'_i18n.ImpersonationBanner' | translate: { number: context.token.userName, name: context.impersonatedContact | lforfl | async } }}</span>
                    <button type="button" class="btn btn-gray px-1 py-0 h-auto" (click)="dropImpersonation()">
                        {{'_i18n.Logout' | translate}}
                    </button>
                </div>
            }
        }
  `,
    styles: [`
        :host {
            display: block;
            background-color: var(--bg-info);
            color: black;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImpersonationBannerComponent {
    private myPhoneService = inject(MyPhoneService);
    tokenInfoService = inject(TokenInfoService);
    readonly impersonatedContact$: Observable<AppContact> = this.myPhoneService.myPhoneSession.pipe(myContact(), startWith(defaultContact));
    private localStorageService = inject(LocalStorageService);

    dropImpersonation() {
        this.localStorageService.clear(LocalStorageKeys.ImpersonateUsername);
        reloadToTeams();
    }
}
