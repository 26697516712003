import { TranslateLoader } from '@ngx-translate/core';
import { Observable, throwError, of, forkJoin } from 'rxjs';
import { languageList } from '@webclient/l10n/languages';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import enAdminLang from '@L10n/admin.json';
import enLinks from '@L10n/links.json';
import { Injectable, inject } from '@angular/core';
import enLang from '@L10n/apps.xml';

interface Lang {
    _i18n: { [key: string]: string };
}

@Injectable()
export class BundleTranslateLoader implements TranslateLoader {
    private http = inject(HttpClient);

    getTranslation(lang: string): Observable<any> {
        const language = languageList.find(value => value.id === lang);
        if (!language) {
            return throwError(() => new Error('Language not found'));
        }
        return forkJoin([enAdminLang, enLinks, enLang, ...language.data].map(data => {
            if (typeof data === 'string') {
                return this.http.get(data);
            }
            else {
                return of(data);
            }
        })).pipe(
            map((langs: Lang[]) => langs.reduce((prev, curr) =>
                ({
                    _i18n: Object.assign(prev._i18n, curr._i18n)
                }),
            {
                _i18n: {}
            }))
        );
    }
}
